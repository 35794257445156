import React from "react"
import PropTypes from "prop-types"

import * as S from "./styled"

const Post = ({
  slug,
  timeToRead,
  title,
  description,
}) => {
  return (
    <S.HtmlLink href={slug} target="_top">
      <S.PostWrapper>
        <S.PostInfo>
          <S.PostTitle>{title}</S.PostTitle>
          <S.PostDate>
            {timeToRead && `${timeToRead} min de leitura`}
          </S.PostDate>
          <S.PostDescription>{description}</S.PostDescription>
        </S.PostInfo>
      </S.PostWrapper>
    </S.HtmlLink>
  )
}

Post.propTypes = {
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  timeToRead: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Post
