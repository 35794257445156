import styled from "styled-components"
import media from "styled-media-query"
import { Link } from 'gatsby'

import * as V from "../../styles/variables"

export const PostWrapper = styled.section`
  display: block;
  padding: 1rem;
  border: ${V.Border.box};
  width: 100%;

  &:not(.disableCard) {
    body#card & {
      border: none;
      padding: 2rem 1rem;
      flex-direction: column;
      justify-content: center;
    }
  }
  ${media.lessThan("large")`
    align-items: flex-start;
    flex-direction: column;
    padding: 1rem 0rem;
  `}
`

export const PostLink = styled(Link)`
  margin: 0 0 ${V.Space.xs} 0;
  padding: 0 ${V.Space.xs};
  color: var(--texts);
  display: flex;
  text-decoration: none;
  `

export const HtmlLink = styled.a`
  padding: 0 ${V.Space.xs};
  color: var(--texts);
  display: flex;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: ${V.BorderRadius.box};
  
  body#card & {
    background-color: var(--background);
  }
  &:hover {
    border: 1px solid var(--borders);
    box-shadow: ${V.BoxShadow.box};
  }
`

export const PostInfo = styled.div`
  display: block;
  margin: 0;
`

export const PostDate = styled.time`
  display: block;
  margin: ${V.Space.xs} 0;
  font-size: 0.7rem;
  color: var(--secondaryColor);
`

export const PostTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: 800;
  margin: 0;
  line-height: 2.4rem;
  body#card & {
    line-height: 1.1;
    margin: 0.8rem 0;
  }
`

export const PostDescription = styled.p`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.8;
  margin-bottom: 1rem;
`
